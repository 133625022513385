.accordion {
  border-bottom: 1px solid #bbb;
  text-align: left;
  outline: none;
  transition: 0.4s;

  span{
    width: calc(100% - 35px);
  }
}

.active,
.accordion:hover {
  background-color: #fff;
}

.panel {
  padding: 0 18px 18px;
  display: none;
  background-color: #D9D9D9;
  overflow: hidden;
}
