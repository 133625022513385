@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color: #ff547d;
  --theme-accent: #0bc154;
  --dark-color: #000000;
  --white-color: #ffffff;
  --background: #d9d9d9;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/font/Inter.ttf");
}

::-webkit-scrollbar {
  height: 12px;
  width: 10px;
  background: #666666;
}

::-webkit-scrollbar-thumb {
  background: #000000;
  // -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px #000000;
}

::-webkit-scrollbar-corner {
  background: #000000;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #000000 #666666;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
}

.sh-blur {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px) brightness(50%);
  -webkit-backdrop-filter: blur(10px) brightness(50%);
}

body {
  margin: 0;
  padding: 0 !important;
  font-size: 16px;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .sub-menu {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: 2s;
    -webkit-transition: 2s;

    &.show {
      display: block;
      visibility: visible;
      opacity: 1;
      transition: 2s;
      -webkit-transition: 2s;
    }
  }

  // .swiper-pagination {
  //   position: relative;
  //   margin-top: 15px;
  // }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  // .swiper-button-prev::after,
  // .swiper-button-next::after {
  //   padding: 10px;
  //   color: #666666;
  //   border-radius: 5px;
  //   -webkit-border-radius: 5px;
  // }

  .sh-button {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  .leading-normal{
    line-height: normal !important;
  }

  

  // .header-menus {
  //   .has-sub-menu:focus > div {
  //     display: flex;
  //   }
  // }

  // .post-list {
  //   & > a {
  //     width: calc(25% - 19px);
  //   }
  // }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
