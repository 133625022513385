.price-slider {
    .price-input {
      width: 100%;
      display: flex;
      margin: 30px 0 35px;
    }
    .price-input .field {
      display: flex;
      width: 100%;
      height: 45px;
      align-items: center;
    }
    .field input {
      width: 100%;
      height: 100%;
      outline: none;
      font-size: 19px;
      margin-left: 12px;
      border-radius: 5px;
      text-align: center;
      border: 1px solid #999;
      -moz-appearance: textfield;
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .price-input .separator {
      width: 130px;
      display: flex;
      font-size: 19px;
      align-items: center;
      justify-content: center;
    }
    .slider {
      height: 5px;
      position: relative;
      background: #ddd;
      border-radius: 5px;
    }
    .slider .progress {
      height: 100%;
      left: 25%;
      right: 25%;
      position: absolute;
      border-radius: 5px;
      background: #603085;
    }
    .range-input {
      position: relative;
    }
    .range-input input {
      position: absolute;
      width: 100%;
      height: 5px;
      top: -5px;
      background: none;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    input[type='range']::-webkit-slider-thumb {
      height: 17px;
      width: 17px;
      border-radius: 50%;
      background: #603085;
      pointer-events: auto;
      -webkit-appearance: none;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    }
    input[type='range']::-moz-range-thumb {
      height: 17px;
      width: 17px;
      border: none;
      border-radius: 50%;
      background: #603085;
      pointer-events: auto;
      -moz-appearance: none;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    }
  }
  
  .ltr .price-slider {
    .price-input {
      margin: 30px 0 35px;
    }
    .field input {
      margin-left: 0;
      margin-right: 12px;
    }
  }
  